<template>
  <div class="home">
    <div class="header column dark center">
      <h1 class="title fire">SMEDSUDDSBADET</h1>
      <br />
      <h3 class="author">FREDRIK PETERSSON</h3>

      <div class="intro" style="">
        En livlös kropp driver iland vid Smedsuddsbadet. Polisen avfärdar det som en drunkningsolycka.<br /><br />
        Mattias, ett cykelintresserat datorsnille, för en ensam och depressiv tillvaro begränsad av panikångest. Den enda vägen han ser ut
        ur sitt osynliga fängelse är genom att begå självmord. <br /><br />
        Men det går inte riktigt som han planerat, och när han börjar dela sitt intresse för kaffe med den nyinflyttade grannen Gerhard
        börjar livet förändras.<br /><br />
        När ännu ett barn dör i vattnet, väcks Gerhards misstankar. Han anar en seriemördare som rör sig till synes obemärkt under
        vattenytan. <br /><br />
        Tillsammans inleder de en amatörmässig utredning som i jakten på rättvisa snabbt förvandlas till en vågad kamp mot det okända där
        gränsen mellan hjältedåd och vansinne suddas ut.
      </div>
    </div>

    <div class="cover darker center" style="">
      <img src="@/assets/cover.jpg" style="max-width:666px;height:auto;width:100%;" />
    </div>
    <div class="order darkest" style="padding-top:20px;">
      <div class="form"  v-if='!orderId' >
        <p>
          Boken är skriven på svenska och de 200 trycka sidorna är i formatet danskt band. Boken skickas på posten inom några dagar.
          <b>Frakten ingår i priset!</b>
        </p>
        <h1 class="margin0 center box" style="">Pris: {{ price }} kr</h1>

        <p>
          Du kan köpa boken genom att swisha <b>{{ price }}&nbsp;kr</b> till&nbsp;{{ swish }}.<br />Ange ditt namn eller
          <b class="fire">{{ code }}</b> som meddelade i Swish.
        </p>
        <div class="desktop center margin0 box" style="">
          <img :src="qr" style="width:200px;border-radius:10px;margin:10px;" /><br />
          Starta swish och scanna QR-koden
        </div>
        <div class="mobile center margin0 box" style="align-items:center;">
          <div style="display:flex;flex-direction:row;align-items:center;">
            <img src="@/assets/swish.png" style="width:50px;height:65px;margin-right:10px;" />

            <h1 style=";margin:0px;flex-grow:1;">1234423992</h1>
          </div>
          <div style="margin-top:10px;"></div>
        </div>

        <div class="orderForm column " style="margin-top:20px;">
          <table style="width:100%;">
            <tr>
              <td colspan="2">Ditt för- och efternamn</td>
            </tr>

            <tr>
              <td colspan="2"><input type="text" value="" v-model="form.name" /></td>
            </tr>

            <tr>
              <td colspan="2" style="padding-top:10px;">Adress</td>
            </tr>

            <tr>
              <td colspan="2"><input type="text" value="" v-model="form.address" /></td>
            </tr>

            <tr>
              <td colspan="1" style="padding-top:10px;">Postnummer</td>
              <td colspan="1" style="padding-top:10px;">Postadress</td>
            </tr>
            <tr>
              <td colspan="1" style="padding-top:0px;">
                <input type="text" value="" style="width:100px;" v-model="form.postno" />
              </td>
              <td style="padding-top:0px;">
                <input type="text" value="" v-model="form.city" />
              </td>
            </tr>

            <tr>
              <td colspan="2" style="padding-top:10px;">E-post dit kvittot ska skickas (måste inte anges)</td>
            </tr>

            <tr>
              <td colspan="2"><input type="text" value="" v-model="form.email" /></td>
            </tr>

            <tr>
              <td colspan="2" style="padding-top:10px;">
                <div style="display:flex;align-items:center;" @click="form.sign = !form.sign">
                  <img :src="src(this.form.sign)" style="width:30px;margin-right:10px;" /> Ja, jag vill ha boken signerad!
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" style="padding-top:10px;">
                <div style="display:flex;align-items:center;" @click="form.news = !form.news">
                  <img :src="src(this.form.news)" style="width:30px;margin-right:10px;" /> Ja, jag vill ha en e-post när en ny bok kommer
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" style="padding-top:10px;">
                <div
                  style="display:flex;align-items:center;"
                  @click="
                    form.swish = !form.swish;
                    swishAlert = false;
                  "
                >
                  <img :src="src(this.form.swish)" style="width:30px;margin-right:10px;" /> Ja, jag har swishat {{ price }}&nbsp;kr!
                  <img src="@/assets/arrow-left.png" v-if="swishAlert" style="width:30px;margin-left:10px;" />
                </div>
              </td>
            </tr>
          </table>
	
          <div class="button" style="" @click="send">SKICKA BESTÄLLNING</div>
        </div>

      </div>
											<div v-if='orderId' class='thanks'>Beställningen är mottagen! OrderID: {{ orderId }}.<br/>Tackar så hjärtligt! </div>
    </div>

    <div class=" dark column" style="">
      <div class="about">
        <div class="">
          <img src="@/assets/fredde.jpg" class="image" style="" />
        </div>
        <div class="text" style="">
          <h3 style="" class="fire">FREDRIK&nbsp;PETERSSON</h3>

          <p style="text-align:left;">
            Fredrik (född -77) har alltid varit intresserad av relationer mellan människor och deras livsöden.<br /><br />
            I sitt debutmanus kombinerar han sin kunskap om att leva med paniksyndrom och en mörk men samtidigt hoppfull berättelse där han
            tar oss med till den där smärtande och lidelsefulla platsen där man inte längre lever, men inte heller är död. En plats okänd
            för de flesta.<br /><br />
            Han leder oss skickligt mellan svåra ämnen som psykisk ohälsa och panikångest men också genom vänskap och hopp. Hela tiden
            kryddat av spänning och intressanta nytänkande vinklingar.
          </p>
        </div>
      </div>
      <div class="contact center" style="padding-top:50px;padding-bottom:50px;" @click="mail">
        <img src="@/assets/icon-mail.png" @click="mail" style="width:50px;margin:10px;" /><br />
        <p>Kontakta mig</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  computed: {
    qr() {
      return "https://api.books.nmd.se/qr/" + this.code + ".png";
    },
    swish() {
      return "1234423992";
    },
    price() {
      return 229;
    }
  },
  data() {
    return {
      show: true,
			code: null,
			orderId: null,
      swishAlert: false,
			//form: { name: "name", address: "Adress", postno: "12345", city: "Dump city", sign: true, email: null, news: true, swish: false }
			form: { name: null, address: null, postno: null, city: null, sign: true, email: null, news: true, swish: false }
    };
  },
  methods: {
    src(item) {
      if (item) {
        return require("@/assets/check-on.png");
      } else {
        return require("@/assets/check-off.png");
      }
    },
    twitter() {
      window.open("https://twitter.com/freddestwitt", "");
    },
    mail() {
      let e = "ma" + "il" + "to:" + "smedsuddsbadet" + "@" + "xtc" + "." + "se";
      window.open(e, "");
    },
    send() {
      if (this.form.name == null || this.form.name.length < 2) {
        alert("Ange ditt för- och efternamn");
        return;
      }

      if (this.form.address == null || this.form.address.length < 2) {
        alert("Ange din adress");
        return;
      }

      if (this.form.postno == null || this.form.postno.length < 2) {
        alert("Ange ditt postnummer");
        return;
      }
      if (this.form.city == null || this.form.city.length < 2) {
        alert("Ange din postadress");
        return;
      }

      if (this.form.swish == null || this.form.swish == false) {
        alert("Swisha pengar och klicka i att du har swishat");
        this.swishAlert = true;
        return;
      }

      const data = this.form;
      data.code = this.code;
      data.swishNo = this.swish;
      data.price = this.price;
      fetch("https://api.books.nmd.se/mail", { method: "POST", body: JSON.stringify(this.form) })
        .then(response => {
          // 1. check response.ok
          if (response.ok) {
            return response;
          }
          return Promise.reject(response); // 2. reject instead of throw
        })
        .then(response => {
          response.json().then(data => {

            console.log("FP:", data.orderId);

            if (data.orderId) {
							alert("Beställingen är mottagen. OrderId: " + data.orderId);
							this.orderId = data.orderId
            } else {
              alert("Kunde inte skicka beställning. Kontakta mig på eposten längst ned på sidan!");
            }
          });

          this.form.name = null;
          this.form.address = null;
          this.form.postno = null;
          this.form.city = null;
          this.form.email = null;
          this.form.swish = false;
          this.form.sign = true;
          this.form.news = true;
        })
        .catch(response => {
          alert(
            "Kunde inte skicka beställning. Kontakta mig på eposten längst ned på sidan!\n\n" + response.status + " " + response.statusText
          );
          console.log(response);
        });
    }
  },
  created() {
    this.code = Math.floor(Math.random() * 100) + 100;
  }
};
</script>

<style scoped>
div.home {
  margin: 0 auto;

  margin-top: 0px;
}

h1.title {
  display: inline-block;

  padding-top: 0px;

  font-size: calc(4vw + 80px);
  letter-spacing: 4px;

  font-family: "Six Caps", Helvetica, Arial, sans-serif;
  display: inline-block;
  margin: 0px;
  margin-top: 10vh;
}

h3.author {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: calc(1vw + 30px);
  color: white;
  transform: scale(1, 1);
  letter-spacing: 5px;
  font-weight: 500;
}

div.intro {
  font-size: calc(0.5vw + 15px);
  text-align: justify;
  padding: 20px;
  max-width: 666px;
  margin: 0 auto;
}

div.about {
  display: flex;
  max-width: 666px;
  margin: 0 auto;
  text-align: center;
  padding-top: 30px;
}

div.about h3 {
  display: inline-block;

  font-size: calc(1vw + 25px);
  letter-spacing: 4px;
}

h4 {
  display: inline-block;

  font-size: calc(1vw + 15px);
  letter-spacing: 4px;
}

div.form {
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 30px;
}

img.image {
  height: auto;
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  min-width: 200px;
}

div.text {
  text-align: left;
  margin-left: 20px;
}

.button {
  background: black;
  padding: 10px;
  font-size: calc(0.4vw + 20px);
  background: -webkit-linear-gradient(#f87903, #fadd4b);
  color: black;
  border-radius: 10px;
  font-weight: bold;
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}

input[type="text"] {
  color: white;
  background: transparent;
  width: calc(100% - 15px);
  font-size: 17px;
  border: 2px solid #f87903;
  border-radius: 10px;
  padding: 5px;
}

.box {
  background: #222;
  padding: 10px;
  border-radius: 10px;
  max-width: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.thanks{
	padding:10px;
	padding: 10px;
  border-radius: 10px;
  background: #222;
  margin-top: 20px;
  margin-bottom: 20px;
	font-size: calc(0.4vw + 16px);
	text-align: center;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.contact img,
.contact p {
  cursor: pointer;
}

@media only screen and (max-width: 666px) {
  div.intro {
    font-size: calc(0.4vw + 16px);
    text-align: left;
    padding: 20px;
    max-width: 666px;
  }

  div.about div.text {
    text-align: center;
    margin: 0px;
    padding: 20px;
  }

  h1.title {
    font-size: calc(4vw + 50px);
    letter-spacing: 2px;
  }

  h3.author {
    font-size: calc(1vw + 20px);

    letter-spacing: 1px;
    font-weight: 500;
  }
  img.image {
    height: auto;
    width: 100%;

    border-radius: 10px;
    min-width: 100px;
    margin: 0px;
  }
  div.about {
    display: inline-block;
    max-width: 666px;
    margin: 0 auto;
    text-align: center;
  }
  div.about h3 {
    display: block;
    font-size: calc(0.5vw + 20px);
    letter-spacing: 4px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
  }
  div.form {
    padding: 20px;
  }

  div.button {
    width: calc(100% - 20px);
    max-width: none;
  }

  div.box,
  h1.box {
    width: calc(100% - 20px);
    max-width: none;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
</style>

